import React, { useState, useEffect } from 'react';
import './App.css';

function getToday() {
  const d = new Date()
  return d.toLocaleDateString('en-CA')
}

const wordMapping = {
  '2022-08-28': 'stand'
}

const sampleMaxList = [['stand'], ['ands', 'dans', 'sand'], ['dan', 'and'], ['na', 'an'], ['a']]

/* generates a regex that will validate any subset of a given input word */
function createRegexForSubset(str) {
  let arr = []
  for (let i = 0; i < str.length; i++) {
    arr.push(str.charAt(i))
    arr.push('?')
  }
  return arr.join('')
}

function validateInputText(currentWordText, str) {
  let currentWordArr = currentWordText.split('')
  for (let i = 0; i < str.length; i++) {
    const char = str.charAt(i)
    const indexMatch = currentWordArr.indexOf(char)

    if (indexMatch === -1) {
      return false
    }
    currentWordArr.splice(indexMatch, 1)
  }
  return true
}

function validateInputTrie(currentNode, items, str) {
  // const possibleAnswers = currentNode.children.flatMap(c => c.node)
  // return possibleAnswers.includes(str)

  if (!currentNode) return false
  if (items.includes(str)) return false

  if (currentNode.node.includes(str)) {
    return currentNode
  }  
  for (const child of currentNode.children) {
    if (child.node.includes(str)) {
      return child
    } else {
      const recurse = validateInputTrie(child, items, str)
      if (recurse) return recurse
    }
  }
  return false
}

function validateMaxSet(items, maxSet) {
  if (items.length !== maxSet.size) {
    return false
  }
  for (let item of items) {
    if (!maxSet.has(item)) {
      return false
    }
  }
  return true
}



function App() {
  // const todaysWord = cask_json.children[0].node[0]
  const today = getToday()
  const todaysWord = wordMapping[today] || 'stand'

  // https://reactjs.org/docs/hooks-reference.html#lazy-initial-state
  const [items, setItemsState] = useState([])

  const [currentNode, setCurrentNode] = useState(null)
  const [text, setText] = useState("")
  const [valid, setValid] = useState(true)
  const [maxSet, setMaxSet] = useState(new Set(sampleMaxList.flat()))
  const [isMaxSet, setIsMaxSet] = useState(false)

  useEffect(() => {
    async function fetchWord() {
      const response = await fetch('./words/' + todaysWord + '.json')
      const root_node = await response.json()

      // using function scoped state to avoid async issues. will save to react state at end of function
      let currentNodeTmp = root_node.children[0]
      let itemsTmp = root_node.children[0].node
      
      // load in previous guesses
      const storedJSON = localStorage.getItem(today)
      if (storedJSON) {
        let previousGuesses = JSON.parse(storedJSON)

        // drop the first word which is the word of the day
        previousGuesses.shift()

        // replay stored guesses
        for (let guess of previousGuesses) {
          console.log('trying guess from local storage', guess)
          const validChild = validateInputTrie(currentNodeTmp, itemsTmp, guess)
          if (validChild) {
            currentNodeTmp = validChild
            itemsTmp.push(guess)
            console.log('saving guess from local storage', guess)
          }
        }
      }
      console.log('itemsTmp is', itemsTmp)
      // save items to local storage
      setCurrentNode(currentNodeTmp)
      setItemsState(itemsTmp)
      localStorage.setItem(today, JSON.stringify(itemsTmp))
    }
    fetchWord()
  // No variable dependencies means this would run only once after the first render
  }, []);

  const currentWord = items[items.length - 1]

  function addItem(newItem) {
    const newItems = items.concat(newItem)
    setItemsState(newItems)
    if (validateMaxSet(newItems, maxSet)) {
      setIsMaxSet(true)
    }
    localStorage.setItem(today, JSON.stringify(newItems))
  }

  function onInputChange(e) {
    const s = e.target.value
    // const validText = validateInputText(currentWord, s)
    // const validChild = validateInputTrie(currentNode, items, s)
    // if (validText === false) {
    //   e.target.setCustomValidity('Word is not valid.')
    // } else {
    //   e.target.setCustomValidity('')
    // }
    setText(s)
    // setValid(validText)
  }
  

  function handleSubmit(e) {
    e.preventDefault();
    if (text.length === 0) {
      return;
    }
    const validChild = validateInputTrie(currentNode, items, text)
    if (validChild) {
      setValid(true)
      setCurrentNode(validChild)
      addItem(text)
      setText('')
    } else {
      setValid(false)
    }
  }

  function shareGame() {
    const redactedAnswer = items.map(word => word.replace(/./g, '·')).join(" ")
    const shareText = `Word game ${getToday()}: ${redactedAnswer}`
    if (navigator.canShare({text: shareText})) {
      navigator.share({text: shareText})
    } else {
      alert(shareText)
    }
  }

  function clearAll() {
    localStorage.removeItem(today)
    // setCurrentNode(cask_json.children[0])
    // setText("")
    // setItems(cask_json.children[0].node)
  }

  return (
    <div className="App">  
      <div>
        <h1>Word Game</h1>
        <WordList items={items} />
        <form onSubmit={handleSubmit}>
          <label htmlFor="new-word">Enter a subset of the current word: </label>
          <input
            id="new-guess"
            onChange={onInputChange}
            value={text}
            required
            // pattern={createRegexForSubset(currentWord)}
          />
          <button>
            Submit
          </button>
        </form>
      </div>
      <p>{!valid ? "Invalid guess!" : ""}</p>
      <p>{isMaxSet ? "Max length reached! 🎉🎉🎉" : ""}</p>
      <button onClick={shareGame}>Share</button>
      <br/><br/><button onClick={clearAll}>Clear</button>
      </div>
  );
}

function WordList({items}) {
  return (<>
    <p>
      {items.slice(0,-1).map(item => (
        <span key={item}>{item} </span>
      ))}
      <span key={items[items.length-1]}><strong>{items[items.length-1]}</strong></span>
    </p>
  </>)
}
export default App;